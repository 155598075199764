import React from 'react';
import Link from 'next/link';
import * as Icon from 'react-feather';
import Image from 'next/image'
import BackendConfig from "@/config/backend-config";
import { useRouter } from 'next/router'

const Dealers = function ({ title,backend, dealers, ...props }) {
    
    const router = useRouter();

    const navigateMap = (url) => {
        window.open (url, '_ blank')
    }

    const ListDealer = (dealers)?dealers.map((data,idx) => {
        
        try{
            if(data.attributes.Gmap && data.attributes.Gmap[0]=='<'){
                var span = document.createElement('span');
                span.innerHTML = data.attributes.Gmap;
                data.attributes.Gmap = span.textContent || span.innerText;
            }
        }
        catch(e){
            
        }
        return (
            <div key={idx} className="col-lg-4 col-sm-6 col-md-6">
                <div className="agency-services-box">
                    <div className="wrap-img dealers">
                        <Image
                            alt='image'
                            src={(data.attributes.Image.data) ? `${BackendConfig["uri"]}${data.attributes.Image.data.attributes.url}`:"/images/blog-image/blog-details.jpg"}
                            layout='fill'
                            objectFit='contain'
                        />
                    </div>

                    <div className="content">
                        <div>
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <th width="30px" className='va-base pb-2'><Icon.Home size={18}/></th>
                                        <td className='va-base pb-2'>{data.attributes.Name} - {data.attributes.Kota}</td>
                                    </tr>
                                    <tr>
                                        <th className='va-base pb-2'><Icon.Phone size={18}/></th>
                                        <td className='va-base pb-2'>{data.attributes.Telp == null ? '-' : data.attributes.Telp}</td>
                                    </tr>
                                    <tr>
                                        <th className='va-base'><Icon.Map size={18}/></th>
                                        <td className='address-text'>
                                            <a onClick={() => navigateMap(data.attributes.Gmap)}>{data.attributes.Address}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        );
    }):"";
    return (
        <div>
            <div className="agency-services-area pt-50 pb-50">
                <div className="container">
                    <div className="section-title st-fs-28">
                        <h2>{title}</h2>
                    </div>

                    <div className="row justify-content">
                        {ListDealer||<div className="bar"></div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dealers;