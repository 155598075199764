import apollo from "../../../lib/apollo";


async function dealersByCompanyId(id){
  var res = await apollo.query(
      `
      query($input : ID!){
        dealers(filters:{Company:{id:{eq:$input}}}){
          data{
            attributes{
              Address
              Name
              Telp
              Email
              Kota
              Gmap
              Image{
                data{
                  attributes{
                    url
                  }
                }
              }
              Region{
                data{
                  attributes{
                    Name
                  }
                }
              }
            }
          }
        }
      }`,'',{
          input:id
      }
  );
  return res;
}

async function dealersByCompanyName(name){
  var res = await apollo.query(
      `
      query($input : String!){
        dealers(filters:{Company:{Name:{eq:$input}}}){
          data{
            attributes{
              Address
              Name
              Telp
              Email
              Kota
              Gmap
              Image{
                data{
                  attributes{
                    url
                  }
                }
              }
              Region{
                data{
                  attributes{
                    Name
                  }
                }
              }
            }
          }
        }
      }`,'',{
          "input":name
      }
  );
  return res;
}

module.exports = {
    dealersByCompanyId:dealersByCompanyId,
    dealersByCompanyName:dealersByCompanyName,
};
