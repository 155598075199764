import React from 'react';
import { Link as LinkScroll } from 'react-scroll'
import Link from 'next/link';
import dynamic from 'next/dynamic';
import parse from 'html-react-parser';
import Image from 'next/image'


const MainBanner = ({company,main_banner,link_to,banner,className}) => {
    var defaultValue;
    if(company == 'thamrin'){
        defaultValue = {
            Title:"Selamat Datang di Thamrin Group",
            Description:"Thamrin Group adalah perusahaan terkemuka di Sumatera Selatan & Bengkulu dengan fokus bisnis pada sektor otomotif dan properti.",
            Image:'/images/bigdata-analytics/main-banner.jpg'
        }
    }
    else if(company == 'yamaha'){
        defaultValue = {
            Title:"Exclusif Motor Untuk Anda",
            Description:"PT.Thamrin Brother's Yamaha merupakan sebuah bisnis company dari Thamrin Group yang bergerak dalam bidang penjualan produk Motor dan Sparepart Yamaha.",
            Image:'/images/Yamaha/banner.jpg'
        }
    }
    else{
        defaultValue = {
            Title:"Exclusif Motor Untuk Anda",
            Description:"",
            Image:'/images/Yamaha/banner.jpg'
        }
    }

    function checkRgba({title, desc, link}) {
        let rgba = 'rgba';
        if((title == '' || title == null) && (desc == '' || desc == null) && (link == '' || link == null)) {
            rgba = ''
        }

        return rgba;
    }

    const Main_Banner = (main_banner && main_banner.length > 0 && main_banner[0].attributes.Contents.data.length>0)?main_banner[0].attributes.Contents.data.map((data,idx)=>{
        return (

                <div key={idx} className="bigdata-analytics-banner">
                    <div className={`bigdata-analytics-content ${checkRgba({title : data.attributes.Title, desc : data.attributes.Description, link : data.attributes.Link})}`} >
                        <img src={`/api/util?img=${encodeURIComponent(data.attributes.Image.data.attributes.url)}`} />
                        <div className='content'>
                            <h1>{data.attributes.Title}</h1>
                            <p>{data.attributes.Description != '' ? parse(data.attributes.Description) : ''}</p>
                            { data.attributes.Link && <a href={`${data.attributes.Link}`} target='_blank' className="btn btn-primary">
                                                                                                Get Started
                                                                                            </a>
                            }
                        </div>
                    </div>
                </div>
        );
    }):(
        <div className="bigdata-analytics-banner" style={{
            'backgroundImage' : `url('${defaultValue.Image}')`,
            
            }}>
            <div className="bigdata-analytics-content" >
                <h1>{defaultValue.Title}</h1>
                <p>{defaultValue.Description != '' ? parse(defaultValue.Description) : ''}</p>
                <LinkScroll to="business_partner" className="btn btn-primary" spy={true} smooth={true}>
                    Get Started
                </LinkScroll>
            </div>
        </div>
    );

    const Banners = (banner && banner.length >0)?banner.map((data,idx)=>{
        return (
                <div key={idx} className="bigdata-analytics-banner">
                    <div className="bigdata-analytics-content-responsive" >
                        <img src={`/api/util?img=${encodeURIComponent(data.attributes.url)}`} />
                    </div>
                </div>
        );
    }):"";
    const OwlCarousel = dynamic(import('react-owl-carousel3'));
    const options = {
        center:false,
        loop: false,
        nav: false,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        margin: 0,
        autoplayTimeout: 5000,
        autoWidth:false,
        responsive: {
            0:{
                items: 1,
            },
        },
    }
    return (
        <div id="home" className={className}>
            <OwlCarousel className="ml-projects-slides owl-carousel fullw owl-theme"
                {...options}>
                {Main_Banner}
            </OwlCarousel>
        </div>
    )
}

export default MainBanner;  